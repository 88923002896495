// スマホでヒーローを全画面にする
// function setHeight() {
//     let vh = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty('--vh', `${vh}px`);
// }
// setHeight();
// window.addEventListener('resize', setHeight);



import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';


var sliderTimer = 5000;
var beforeEnd = 500;

var $imageSlider = $(".c-hero-slider");
$imageSlider
    .on("init", function(event, slick) {
        $(".c-hero-slider-num__now_count").text(slick.currentSlide + 1);
        $(".c-hero-slider-num__all_count").text(slick.slideCount);
    })
    .slick({
        autoplay: true,
        autoplaySpeed: sliderTimer,
        fade: true,
        speed: 1000,
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        // Custom arrows
        prevArrow: $(".c-hero-slider-custom-arrows__prev_arrow"),
        nextArrow: $(".c-hero-slider-custom-arrows__next_arrow"),
    })
    .on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        $(".c-hero-slider-num__now_count").text(nextSlide + 1);
    });

function progressBar() {
    $(".c-hero-slider-progress").find("span").removeAttr("style");
    $(".c-hero-slider-progress").find("span").removeClass("active");
    setTimeout(function() {
        $(".c-hero-slider-progress")
            .find("span")
            .css("transition-duration", sliderTimer / 1000 + "s")
            .addClass("active");
    }, 100);
}
progressBar();
$imageSlider.on("beforeChange", function(e, slick) {
    progressBar();
});
$imageSlider.on("afterChange", function(e, slick, nextSlide) {

});
