import '../../Components/_Slider.js';

document.addEventListener("DOMContentLoaded", function() {
    // 初期化
    var heroLogo = document.querySelector('.c-hero_logo--sticky');
    var topAboutLogo = document.querySelector('.c-hero_logo--about');
    var heroLogoHeight = heroLogo.offsetHeight;
    var topAboutLogoHeight = topAboutLogo.offsetHeight;

    function checkPosition() {
        var topAboutLogoTop = topAboutLogo.getBoundingClientRect().top;

        // 追従するロゴがc-hero_logo--aboutに接触した場合
        if (topAboutLogoTop <= heroLogoHeight && topAboutLogoTop > -topAboutLogoHeight) {
            heroLogo.style.opacity = '0'; // ロゴを非表示に
        } else if (topAboutLogoTop <= -topAboutLogoHeight) {
            heroLogo.style.position = 'absolute';
            heroLogo.style.top = (topAboutLogo.offsetTop - heroLogoHeight) + "px";
        } else {
            heroLogo.style.opacity = '1'; // ロゴを再表示に
            heroLogo.style.position = 'fixed';
            heroLogo.style.top = '17%';
        }
    }

    window.addEventListener('scroll', checkPosition);


});
